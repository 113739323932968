import * as BasePopover from '@radix-ui/react-popover';
import styled from "styled-components";

const StyledPopoverContent = styled(BasePopover.Content)`
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.26);
    outline: none;
    
    padding: 16px;
    border-radius: 16px;
    background-color: rgba(0, 0, 0, 0.95);
    color: white;
    font-size: 14px;
    font-weight: 600;
    line-height: 16.94px;
    letter-spacing: -0.02em;
    width: 300px;
    
    z-index: 10000;
`

export const Popover = Object.assign(BasePopover.Root, {
  Trigger: BasePopover.Trigger,
  Content: StyledPopoverContent,
  Portal: BasePopover.Portal,
  Close: BasePopover.Close,
  Arrow: BasePopover.Arrow,
  Anchor: BasePopover.Anchor,
});
