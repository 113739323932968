import { useWebApp } from "@vkruglikov/react-telegram-web-app";
import { WebApp } from "@vkruglikov/react-telegram-web-app/lib/core/twa-types";
import { useContext, useEffect, useState } from "react";
import useWebSocket from "react-use-websocket";
import styled from "styled-components";
import { UserContext } from "../../contexts/user";
import { ReactComponent as Blessings } from '../../images/stars2.svg';
import { PageContainer } from "../../shared/components/page-container";
import { Space } from "../../shared/components/space";
import { Message, StoreProductItem } from "../../types";
import { formatFaithValue } from "../../utils";
import { ProductCard } from "./product-card";
import { ProductModal } from "./product-modal";

interface StoreItemsType {
  items: StoreProductItem[];
}

interface StoreInvoiceType {
  invoice: string;
}

interface StoreMessage extends Message {
  data: StoreItemsType | StoreInvoiceType;
}

const CardsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(170px, 1fr));
  gap: 9px;
`

const Balance = styled.div`
    display: flex;
    align-items: center;
    margin-left: auto;
    font-family: 'Oswald';
    font-size: 20px;
    color: white;
    font-weight: 500;
    letter-spacing: -0.02em;
`

export const ShopPage = () => {
  const [itemToOpenModal, setItemToOpenModal] = useState<StoreProductItem | null>(null);
  const [storeItems, setStoreItems] = useState<StoreProductItem[]>([]);

  const [loading, setLoading] = useState(true);
  const [loadingItemsIds, setLoadingItemsIds] = useState<number[]>([]);

  const [activeInvoice, setActiveInvoice] = useState<string | undefined>(undefined);

  const user = useContext(UserContext);

  const webApp: WebApp = useWebApp()

  const { sendJsonMessage, lastJsonMessage } = useWebSocket<
    StoreMessage
  >(process.env.REACT_APP_WSS_ENDPOINT!, {
    filter: (m) => ['store:front', 'store:invoice'].indexOf(JSON.parse(m.data).method) >= 0,
    share: true,
    shouldReconnect: () => true,
  });

  useEffect(() => {
    sendJsonMessage({"method": "store:front"})
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (!lastJsonMessage || lastJsonMessage.error) return;

    if (lastJsonMessage.method === 'store:front') {
      const data = lastJsonMessage.data as StoreItemsType;
      setStoreItems(data.items);
      setLoading(false);
      setLoadingItemsIds([]);

    } else if (lastJsonMessage.method === 'store:invoice') {
      const data = lastJsonMessage.data as StoreInvoiceType;
      setActiveInvoice(data.invoice);

    }
  }, [lastJsonMessage])

  useEffect(() => {
    if (!activeInvoice) return;

    webApp.openInvoice!(activeInvoice, (status) => {
      if (status === 'paid') {
        setTimeout(() => sendJsonMessage({ method: 'store:front' }), 3000)
      } else {
        setLoadingItemsIds([])
      }
    });
  }, [activeInvoice])

  const buyItem = (item: StoreProductItem) => {
    setItemToOpenModal(null)
    sendJsonMessage({"method": "store:buy", "item_id": item.id})
    setLoadingItemsIds((prev) => ([...prev, item.id]));
  }

  const equipItem = (item: StoreProductItem) => {
    setItemToOpenModal(null)
    if (item.pid === null) return;

    sendJsonMessage({"method": "store:use", "purchase_id": item.pid })
    setLoadingItemsIds((prev) => [...prev, item.id]);
  }

  const unequipItem = (item: StoreProductItem) => {
    setItemToOpenModal(null)
    if (item.pid === null) return;

    sendJsonMessage({"method": "store:unuse", "purchase_id": item.pid })
    setLoadingItemsIds((prev) => [...prev, item.id]);
  }

  const handleClickItem = (item: StoreProductItem) => {
    setItemToOpenModal(item);
  }

  return <PageContainer>
    <Space
      style={{ fontWeight: 600, fontSize: 22, marginBottom: 12, lineHeight: '35px' }}
      align='center'
      justify='space-between'>
        <span>Shop</span>
        <Balance>
          <Blessings height={24} width={24} fill={'white'}/>
          &nbsp;{formatFaithValue(user.balance)}&nbsp;FAITH
        </Balance>
    </Space>
    {loading && (
      <div style={{ flex: '1 1 26px' }}>
        <span className="page loader" />
      </div>
    )}
    <CardsContainer>
      {storeItems && storeItems.map((item) =>
        <ProductCard
          key={item.id}
          product={item}
          onClick={() => handleClickItem(item)}
          loading={loadingItemsIds.includes(item.id)}
        />
      )}
    </CardsContainer>
    {itemToOpenModal && <ProductModal
        item={itemToOpenModal}
        onBuy={buyItem}
        onEquip={equipItem}
        onUnequip={unequipItem}
        onClose={() => setItemToOpenModal(null)}
        isOpen={!!itemToOpenModal}
    />}
  </PageContainer>;
}
