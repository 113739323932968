import { TonApiClient } from '@ton-api/client';
import { Address } from '@ton/core';

export type NFTItem = {
  image: string,
  name: string,
  collection: string
}

export type MaybeNFTItem = NFTItem | undefined

export async function getNFTItem(nftAddress: string) {
  const ta = new TonApiClient({
    baseUrl: 'https://tonapi.io',
    // apiKey: 'YOUR API KEY'
  });

  const item = await ta.nft.getNftItemByAddress(Address.parse(nftAddress));

  const result = {
    collection: item.collection?.toString(),
    image: item.metadata['image'],
    name: item.metadata['name']
  }

  return result as NFTItem;
}
